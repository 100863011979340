<template>
  <div class="mod-config" >
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="currentChangeHandle(1)" @submit.native.prevent>
      <!--      所属渠道标题与选择-->
      <table class="detailTable_search" >
        <tr>
      <td class="titleTd width80px">所属渠道</td>
      <td class="valueTd">
        <sysDictSelect v-model="dataForm.channelType" dictType="channel_type" placeholder="请选择" style="width:100%"  filterable/>
      </td>
        </tr>
      </table>
      <div class="wxts_msg_search" >
        <div class="f_t" >
          <el-button-group :disabled="dataListLoading">
            <el-button size="mini" @click="currentChangeHandle(1)" type="success" :loading="dataListLoading">查询</el-button>
            <el-button size="mini" v-if="isAuth('dataflow:olfcoveruser:save')" type="primary" @click="addOrUpdateHandle()">新增</el-button>
            <el-button size="mini" v-if="isAuth('dataflow:olfcoveruser:delete')" type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0">批量删除</el-button>
          </el-button-group>
        </div>
      </div>
    </el-form>
    <el-table
        :data="dataList"
        border
        v-loading="dataListLoading"
        @selection-change="selectionChangeHandle"
        style="width: 100%;">
      <el-table-column
          type="selection"
          header-align="center"
          align="center"
          width="50">
      </el-table-column>
      <el-table-column
          prop="areaCode"
          header-align="center"
          align="center"
          label="所属区域">
      </el-table-column>

      <el-table-column
          prop="channelType"
          header-align="center"
          align="center"
          dictType="channel_type"
          label="所属渠道">
        <template slot-scope="scope">
          <sysDict type="channel_type" :value="scope.row.channelType"></sysDict>
        </template>
      </el-table-column>
      <el-table-column
          prop="coverBase"
          header-align="center"
          align="center"
          label="用户基数(单位：个)">
      </el-table-column>
      <el-table-column
          prop="coverSum"
          header-align="center"
          align="center"
          label="用户数(单位：个)">
      </el-table-column>
      <el-table-column
          prop="fillUser"
          header-align="center"
          align="center"
          label="维护人">
      </el-table-column>
      <el-table-column
          prop="fillDate"
          header-align="center"
          align="center"
          label="维护时间">
      </el-table-column>
      <el-table-column
          fixed="right"
          header-align="center"
          align="center"
          width="150"
          label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" v-if="isAuth('dataflow:olfcoveruser:update')" @click="addOrUpdateHandle(scope.row.id)">修改</el-button>
          <el-button type="text" size="small" class="del-button" v-if="isAuth('dataflow:olfcoveruser:delete')" @click="deleteHandle(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalCount"
        layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
  </div>
</template>

<script>
import AddOrUpdate from './add-or-update'
import { getList , deleteData } from '@/api/dataflow/olfcoveruser.js'
export default {
  data () {
    return {
      dataForm: {
        channelType: '',
      },
      grid: null,
      dataList: [],
      regId:'',
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false
    }
  },
  components: {
    AddOrUpdate
  },
  activated () {
    //this.dataForm.key =  this.$route.query.key;

    let pageIndex = this.$route.query.pageIndex;
    if(pageIndex) {
      this.pageIndex = parseInt(pageIndex);
    }

    let pageSize = this.$route.query.pageSize;
    if(pageSize) {
      this.pageSize = parseInt(pageSize);
    }

    this.getDataList();
  },
  methods: {
    refresh(){
      // this.$router.push({name:'dataflow_olfcoveruser',query: {key:this.dataForm.key,pageIndex:this.pageIndex,pageSize:this.pageSize}});
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      const params_ = {
        'page': this.pageIndex,
        'limit': this.pageSize,
        //'areaCode': this.$cookie.get("grid")?this.$cookie.get("grid"):this.$cookie.get("regionId"),
        'areaCode': this.$store.state.user.gridId === undefined ? this.$store.state.user.regionId : this.$store.state.user.gridId,
        'channelType': this.dataForm.channelType,
      }
      getList(params_).then(({data}) => {
        if (data && data.code === 0) {
          this.dataList = data.data.list
          this.totalCount = data.data.totalCount
        } else {
          this.dataList = []
          this.totalCount = 0
          this.$message.error(data.msg)
        }
      }).catch((err) => {
        console.error(err)
      }).finally(()=>{
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
      this.refresh()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
      this.refresh()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    // 新增 / 修改
    addOrUpdateHandle (id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id)
      })
    },
    // 删除
    deleteHandle (row) {
      const ids = row ? [row.id] : this.dataListSelections.map(item => {
        return item.id
      })
      //const names = row ? [row.name] : this.dataListSelections.map(item => {
      //  return item.name
      //})

      this.$confirm(`确定要进行[${row ? '删除' : '批量删除'}]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.dataListLoading = true
        deleteData(ids).then(({data}) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000
            })
            this.getDataList()
          } else {
            this.$message.error(data.msg)
          }
        }).catch((err) => {
          console.error(err)
        }).finally(()=>{
          this.dataListLoading = false
        })
      }).catch(_ => {})
    }
  }
}
</script>
