import http from '@/utils/httpRequest'
// 列表获取
export function getList(params) {
    return http({
        url: http.adornUrl('/dataflow/olfcoveruser/list'),
        method: 'get',
        params: params
    })
}

// 删除数据
export function deleteData(ids = []) {
    return http({
        url: http.adornUrl("/dataflow/olfcoveruser/delete"),
        method: "post",
        data: http.adornData(ids, false)
    })
}

// 根据ID 获取
export function getById(id) {
    return http({
        url: http.adornUrl(`/dataflow/olfcoveruser/info/${id}`),
        method: "get"
    })
}
// 更新或新增
export function saveOrUpdate(data) {
    return http({
        url: http.adornUrl(
            `/dataflow/olfcoveruser/${!data.id ? "save" : "update"}`
        ),
        method: "post",
        data: data
    })
}


export function getAreaName(code) {
    return http({
        url: http.adornUrl(`/sys/region/getAreaName/${code}`),
        method: "get"
    })
}

//获取用户基数
export function getCoverBase() {
    return http({
        url: http.adornUrl(`/dataflow/olfcoveruser/getCoverBase`),
        method: "post"
    })
}
//获取覆盖用户统计时间
export function getCoverUserDate(){
    return http({
        url: http.adornUrl(`/dataflow/olfcoveruser/getCoverUserDate`),
        method: "post"
    })
}
export function getOnlineUserDate(){
    return http({
        url: http.adornUrl(`/dataflow/olfcoveruser/getOnlineUserDate`),
        method: "post"
    })
}
export function getOnrealUserDate(){
    return http({
        url: http.adornUrl(`/dataflow/olfcoveruser/getOnrealUserDate`),
        method: "post"
    })
}