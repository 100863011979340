<template>
  <el-dialog
      :title="!dataForm.id ? '新增' : '修改'"
      :close-on-click-modal="false"
      :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px">
      
      <el-form-item label="所属区域" prop="areaCode" label-width="86.3px">
        <el-input v-model="dataForm.areaCode" placeholder=" " :readonly="true"></el-input>
      </el-form-item>  
      <el-form-item label="所属渠道" prop="channelType" label-width="86.3px">
        <sysDictSelect v-model="dataForm.channelType" dictType="channel_type" placeholder="请选择所属渠道" filterable/>
      </el-form-item>
<!--      <el-row v-if="dataForm.id">-->
        <el-form-item label="用户基数" prop="coverBase" label-width="86.3px">
          <el-input-number  v-model="dataForm.coverBase"  :min="0" :max="999999999" placeholder="请输入覆盖用户基数"></el-input-number>
        </el-form-item>
<!--      </el-row>-->
      <el-form-item label="新增用户数" prop="coverSum" label-width="auto">
        <el-input-number  v-model="dataForm.coverSum"  :min="0" :max="999999999" placeholder="请输入用户数" ></el-input-number>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer" v-loading="isOnSubmit">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" :disabled="isOnSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getById , saveOrUpdate } from '@/api/dataflow/olfcoveruser.js'
import {getAreaName,getCoverBase} from '@/api/dataflow/olfcoveruser.js'
import {dateFormat} from "@/utils";
export default {
  data () {
    return {
      visible: false,
      isOnSubmit: false,
      dataForm: {
        id: null,
        coverSum: '',
        channelType: '',
        fillUser: '',
        areaCode: '',
        fillDate: '',
        dataState:'',
        coverBase:''
      },
      dataRule: {
        coverSum: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ],
        channelType: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ],
        fillUser: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ],
        areaCode: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ],
        fillDate: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ],
        dataState:[
          { required: true, message: '不能为空', trigger: 'blur' }
        ],
      }
    }
  },
  methods: {
    init (id) {
      this.dataForm.id = id
      this.visible = true
      this.isOnSubmit = false
      this.dataForm.fillDate = dateFormat(new Date,'yyyy-MM-dd')
      const code = this.$store.state.user.regionId !== undefined ? this.$store.state.user.regionId : '0'
      this.getAreaname(code)
     // this.dataForm.areaCode = this.$store.state.user.regionId !== undefined ? this.$store.state.user.regionId : '0'
      this.getCoverBase()
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
        if (this.dataForm.id) {
          getById(id).then(({data}) => {
            if (data && data.code === 0) {
              this.dataForm.coverSum = data.data.coverSum
              this.dataForm.channelType = data.data.channelType
              //this.dataForm.fillUser = data. this.$store.state.user.data.fillUser
              this.dataForm.areaCode = data.data.areaCode
              this.dataForm.fillDate = data.data.fillDate
              this.dataForm.coverBase = data.data.coverBase
              this.dataForm.dataState = data.data.dataState
            }
          }).catch((err) => {
            this.$message.error(err)
          })
        } else {
          if(this.$store.state.user.regionId) {
            this.dataForm.areaCode = this.$store.state.user.gridId;
          } else {
            this.dataForm.areaCode = this.$store.state.user.regionId;
          }
          
        }
      })
    },

    //获取所属区域
    getAreaname(code) {
      getAreaName(code).then(({data}) => {
        this.dataForm.areaCode = data.data
      })
    },

    //获取用户基数
    getCoverBase() {
      getCoverBase().then(({data}) => {
        this.dataForm.coverBase = data.data.coverBase
      })
    },

    // 表单提交
    dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.isOnSubmit = true
          const data_ = {
            'id': this.dataForm.id || undefined,
            'coverSum': this.dataForm.coverSum,
            'channelType': this.dataForm.channelType,
            //'fillUser': this.dataForm.fillUser,
            'areaCode': this.dataForm.areaCode,
            'coverBase': this.dataForm.coverBase,
            'fillDate': this.dataForm.fillDate,
            'dataState':this.dataForm.dataState,
          }
          saveOrUpdate(data_).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 500
              })
              this.visible = false
              this.$emit('refreshDataList')
            } else {
              this.$message.error(data.msg)
            }
          }).catch((err) => {
            this.$message.error(err)
          }).finally(()=>{
            this.isOnSubmit = false
          })
        }
      })
    }
  }
}
</script>
